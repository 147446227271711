.event-list .table-wrapper table {
    text-align: left;
}

.event-list .table-wrapper .table-header {
    font-weight: bolder;
    background: #5a0065;
    color: #fff;
}

.event-list .table-wrapper td {
    padding: 0 1em;
}

.event-list .table-wrapper {
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
}
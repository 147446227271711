.certificate-selection-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;
}

@media screen and (max-width: 768px) {
    .certificate-selection-wrapper {
        flex-direction: column;
    }
}

.certificate-selection-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: 1em;
    height: 250px;
    width: 250px;
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    cursor: pointer;
    user-select: none;
}

.certificate-selection-block:hover {
    border: 1px solid #7c7c7c;
}

.certificate-selection-block .certificate-selection-block-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    padding-top: 1em;
    width: 100%;
    color: #5a0065;
}

.certificate-selection-block .certificate-selection-block-icon > * {
    min-width: 90px;
    min-height: 90px;
}

.certificate-selection-block .certificate-selection-block-icon img {
    max-height: calc(230px - 2em);
    max-width: calc(230px - 2em);
    margin: 1em;
}

.certificate-selection-block h4,
.certificate-selection-block h5 {
    margin: 0;
}

.certificate-selection-block .certificate-selection-block-title {
    font-weight: 400;
    font-size: 0.9rem;
}

.certificate-selection-block .certificate-selection-block-price {
    font-weight: 400;
    font-size: 0.7rem;
    margin-bottom: 1em;
    color: #8a4793;
}

.certificate-selection-wrapper > .inside-out-logo-wrapper {
    flex-grow: 1;
    width: 100%;
    align-self: flex-start;
    margin-bottom: 2em;
}
.radio-group-wrapper fieldset {
    border: none;
}

.radio-group-wrapper fieldset {
    padding: 0;
    margin: 0 0 0.5em;
}

.radio-wrapper {
    display: inline-block;
    margin: 0 0.5em;
}

.radio-wrapper .description {
    margin: 0 0.1em;
    user-select: none;
}

.radio-wrapper input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 1px solid #d6d6d6;
    transition: 0.1s all ease-in-out;
    margin-right: 5px;

    position: relative;
    top: 4px;

    cursor: pointer;
}

.radio-group-wrapper.fix-me input {
    border: 1px solid #d6283b;
}

.radio-group-wrapper.fix-me .description {
    color: #d6283b;
}

.radio-wrapper input:hover {
    border: 1px solid #7c7c7c;
}

.radio-wrapper input:checked {
    border: 6px solid #3e0346;
}


.input-field-wrapper {
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    font-size: 1em;
    position: relative;
    width: 250px;
    height: 3em;
    margin-bottom: 0.5em;
}

.input-field-wrapper.fix-me {
    border: 1px solid #d6283b;
}

.input-field-wrapper .label .required-indicator {
    opacity: 0;
}

.input-field-wrapper.required .label .required-indicator {
    opacity: 1;
}

.input-field-wrapper .label {
    position: absolute;
    opacity: 0.4;
    top: 1em;
    left: 1em;
    font-size: 1em;
    transition: 0.1s ease-in-out;
    z-index: 5;
}

.input-field-wrapper.dirty .label {
    font-size: 0.7em;
    top: 0.3em;
    left: 1.5em;
}

.input-field-wrapper input {
    position: absolute;
    border: none;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    padding: 1.2em 1em 0.5em;
    font-size: 1em;
    z-index: 10;
    border-radius: 8px;
}
.data-management-form .inside-out-form-body .form-section {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em;
}

.data-management-section {
    max-width: 400px;
    margin: 0 auto;
}

.data-management-form .registration-date-info {
    font-size: 10px;
}

.data-management-section .managed-data {
    max-width: 400px;
    text-align: left;
}

.data-removal p {
    max-width: 400px;
    font-size: 12px;
}

.data-removal .delete-data-link {
    color: #3e0346;
    text-decoration: none;
    cursor: pointer;
}
.price-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.price-info-wrapper .price-info-label {
    padding-right: 0.5em;
}

.price-info-wrapper .price-info-value {
    color: #5a0065;
}

.price-info-wrapper .price-info-value .literal-value {
    font-weight: 600;
    font-size: 1.2em;
    color: #3e0346;
}

.price-info-wrapper .break {
    flex-basis: 100%;
    height: 0;
}

.price-info-wrapper .shipping-info {
    flex-grow: 1;
    font-size: 10px;
    color: #8a4793;
}
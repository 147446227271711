.loading-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: #fff;
    background-color: #4c2851;

    display: flex;
    justify-content: center;
    align-items: center;
}

.inside-out-form-admin {
    text-align: center;
}

.inside-out-form-admin-header {
    background-color: #5a0065;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1em;
    border-bottom: 5px solid #3e0346;
    z-index: 100;
    position: relative;
    user-select: none;
}

.inside-out-form-admin-header .inside-out-form-admin-title {
    margin: 0;
    font-weight: 400;
}

.inside-out-form-admin-header .inside-out-form-admin-title {
    text-transform: uppercase;
    font-size: 0.9rem;
    opacity: 0.9;
}

.inside-out-form-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(80vh);
    margin-top: 1em;
}

.inside-out-form-body.hide {
    animation-name: fade-out;
    animation-iteration-count: 1;
    animation-duration: 0.1s;
    opacity: 0;
}

.inside-out-form-body.show {
    animation-name: fade-in;
    animation-iteration-count: 1;
    animation-duration: 0.1s;
    opacity: 1;
}

.inside-out-form-link {
    color: #61dafb;
}

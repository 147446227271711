.menu-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;
}

@media screen and (max-width: 768px) {
    .menu-wrapper {
        flex-direction: column;
    }
}

.menu-wrapper .menu-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: 1em;
    height: 250px;
    width: 250px;
    border-radius: 8px;
    border: 1px solid #d6d6d6;
    cursor: pointer;
    user-select: none;
}

.menu-wrapper .menu-block:hover {
    border: 1px solid #7c7c7c;
}

.menu-wrapper .menu-block .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
    padding-top: 1em;
    width: 100%;
    color: #5a0065;
}

.menu-wrapper .menu-block .menu-icon > * {
    min-width: 90px;
    min-height: 90px;
}

.menu-wrapper .menu-block h4 {
    margin: 0;
}

.menu-wrapper .menu-block .menu-item-title {
    font-weight: 400;
    font-size: 0.9rem;
    margin-bottom: 1em;
}
.loading-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: #fff;
    background-color: #4c2851;

    display: flex;
    justify-content: center;
    align-items: center;
}

.inside-out-form {
    text-align: center;
}

.inside-out-form-header {
    background-color: #5a0065;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1em;
    border-bottom: 5px solid #3e0346;
    z-index: 100;
    position: relative;
    user-select: none;
}

.inside-out-form-header .inside-out-form-title,
.inside-out-form-header .inside-out-form-event {
    margin: 0;
    font-weight: 400;
}

.inside-out-form-header .inside-out-form-title {
    text-transform: uppercase;
    font-size: 0.9rem;
    opacity: 0.9;
}

.inside-out-form-header .inside-out-form-event {
    font-size: 1.5rem;
}

.inside-out-form-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(80vh);
    margin-top: 1em;
}

.inside-out-form-body.hide {
    animation-name: fade-out;
    animation-iteration-count: 1;
    animation-duration: 0.1s;
    opacity: 0;
}

.inside-out-form-body.show {
    animation-name: fade-in;
    animation-iteration-count: 1;
    animation-duration: 0.1s;
    opacity: 1;
}

.inside-out-form-link {
    color: #61dafb;
}

.payment-confirmation .loading-icon {
    width: 32px;
    height: 32px;
    color: #5a0065;
}

.payment-confirmation .payment-check {
    width: 32px;
    height: 32px;
    color: #0c9112;
    animation-name: zoom-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.resend-link {
    color: #3e0346;
    text-decoration: none;
    cursor: pointer;
}

.payment-confirmation .payment-confirm {
    width: 32px;
    height: 32px;
    color: #322939;
    animation-name: zoom-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.payment-confirmation .payment-error {
    width: 32px;
    height: 32px;
    color: #af1a20;
    animation-name: zoom-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.payment-confirmation .payment-number {
    margin-top: 2em;
    font-size: 10px;
    color: #878787;
}

.payment-confirmation {
    padding-bottom: 2em;
}

.payment-confirmation .inside-out-logo-wrapper {
    margin-bottom: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes zoom-in {
    0% {
        width: 0;
        height: 0;
        margin: 16px;
    }

    100% {
        width: 32px;
        height: 32px;
        margin: 0;
    }
}
.submit-wrapper {
    margin-bottom: 0.5em;
}

.submit-wrapper input {
    border: none;
    border-radius: 8px;
    padding: 0.5em 1em;
    font-size: 15px;
    background: #3e0346;
    color: #fff;
    cursor: pointer;
}

.submit-wrapper input:hover {
    background: #3e0346;
    color: #eee;
}

.submit-wrapper input:active {
    background: #7c7c7c;
    color: #fff;
}
.certificate-form {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
}

.certificate-form .form-column {
    margin: 0.5em 1em;
    width: 240px;
}

.certificate-form .form-column-stretched {
    flex-grow: 1;
    width: 100%;
}

.form-section {
    margin-bottom: 1.5em;
}

.form-section .form-section-description {
    font-size: 0.7em;
    color: #3e0346;
}
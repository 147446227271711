.toolbar {
    margin: 0;
    background: #3e0346;
    color: #fff;
    padding-bottom: 0.5em;
    z-index: 5;
    display: flex;
    flex-flow: row;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.toolbar.show {
    animation-name: toolbar-slide-in;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    margin: 0;
}

.toolbar.hide {
    animation-name: toolbar-slide-out;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    margin-top: -100%;
    margin-bottom: 100%;
}

.toolbar .go-back-button {
    align-self: flex-start;
    padding: 0 0.5em;
}

.toolbar .chevron-left {
    max-height: 50%;
    padding: 0 0.5em;
}

.toolbar .currently-chosen {
    flex-grow: 1;
    margin-right: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: -1em;
    text-transform: uppercase;
    font-size: 0.8rem;
}

@keyframes toolbar-slide-in {
    0% {
        margin-top: -100%;
        margin-bottom: 100%;
    }

    100% {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@keyframes toolbar-slide-out {
    0% {
        margin-top: 0;
        margin-bottom: 0;
    }

    100% {
        margin-top: -100%;
        margin-bottom: 100%;
    }
}
.sorry-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: #fff;
    background-color: #4c2851;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.sorry-placeholder .sorry-icon {
    width: 32px;
    height: 32px;
}

.sorry-placeholder a {
    color: #fff;
}
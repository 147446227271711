.checkbox-wrapper {
    text-align: left;
    font-size: 14px;
    margin-bottom: 1em;
    position: relative;

    cursor: pointer;
}

.checkbox-wrapper.fix-me {
    color: #d6283b;
}

.checkbox-wrapper input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 16px;
    height: 16px;

    margin-right: 1em;

    border: none;
    background: transparent;

    cursor: pointer;
}

.checkbox-wrapper label {
    cursor: pointer;
    user-select: none;
}

.checkbox-wrapper.required label {
    font-weight: 500;
}

.checkbox-wrapper label .required-indicator {
    opacity: 0;
}

.checkbox-wrapper.required label .required-indicator {
    opacity: 1;
}

.checkbox-wrapper .check {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 16px;
    height: 16px;
    transition: 0.1s all linear;
    opacity: 0;
    color: #5a0065;
}

.checkbox-wrapper .empty {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 16px;
    height: 16px;
    transition: 0.1s all linear;
    opacity: 0.2;
    color: #5a0065;
}

.checkbox-wrapper:hover .empty {
    opacity: 0.5;
}

.checkbox-wrapper.checked .check {
    opacity: 1;
}

.checkbox-wrapper.checked .empty {
    opacity: 0;
}
